@import 'client/assets/styles';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  @include text('body1', 'semiBold');
  color: $n800;
}
