@import '../base/';

@mixin text($type, $font: 'regular') {
  @if $type == 'display' {
    font-size: $textDisplayFontSize;
    line-height: $textDisplayLineHeight;
  } @else if $type == 'h1' {
    font-size: $textH1FontSize;
    line-height: $textH1LineHeight;
  } @else if $type == 'h2' {
    font-size: $textH2FontSize;
    line-height: $textH2LineHeight;
  } @else if $type == 'h3' {
    font-size: $textH3FontSize;
    line-height: $textH3LineHeight;
  } @else if $type == 'h4' {
    font-size: $textH4FontSize;
    line-height: $textH4LineHeight;
  } @else if $type == 'body1' {
    font-size: $textBody1FontSize;
    line-height: $textBody1LineHeight;
  } @else if $type == 'body2' {
    font-size: $textBody2FontSize;
    line-height: $textBody2LineHeight;
  } @else if $type == 'small' {
    font-size: $textSmallFontSize;
    line-height: $textSmallLineHeight;
  } @else if $type == 'xsmall' {
    font-size: $textXSmallFontSize;
    line-height: $textXSmallLineHeight;
  } @else {
    font-size: $textTinyFontSize;
    line-height: $textTinyLineHeight;
  }
  @if $font == 'regular' {
    font-weight: $fontWeightRegular;
  } @else if $font == 'semiBold' {
    font-weight: $fontWeightSemiBold;
  } @else {
    font-weight: $fontWeightBold;
  }
}

@mixin default-font-family {
  font-family: $fontFamily;
}

@mixin label {
  margin-bottom: $spacingXXs;
}

@mixin link-style(
  $textColor: $b400,
  $type: 'body2',
  $font: 'semiBold',
  $textDecoration: underline
) {
  a:any-link,
  a:visited,
  a:active {
    @include text($type, $font);

    color: $textColor;
    text-decoration: $textDecoration;
  }
}
