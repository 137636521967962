@import 'client/assets/styles';

.button {
  position: relative;
  margin: 0;
  padding: 0 8px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  transition: background-color 0.2s;
}

.buttonContent {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
}

.iconStart,
.iconEnd {
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconStart {
  margin-right: 6px;
}

.iconEnd {
  margin-left: 6px;
}

.textSizeSmall {
  @include text('small', 'semiBold');
}

.textSizeMedium {
  @include text('body2', 'semiBold');
}

.widthSmall {
  width: auto;
}

.widthLarge {
  width: 100%;
}

.heightXS {
  height: 32px;
  min-width: 40px;
}

.heightSmall {
  height: 40px;
  min-width: 40px;
}

.heightLarge {
  height: 48px;
  min-width: 48px;
}

.hide {
  opacity: 0;
}

.notAllowed {
  cursor: not-allowed;
}

.green {
  color: $n800;
  background-color: $ba500;

  &:hover {
    background-color: $ba600;
  }
}

.greenDisabled,
.greenLoading {
  background-color: $n400;
  color: $n500;
  cursor: not-allowed;

  &:hover {
    background-color: $n400;
  }
}

.blue {
  color: $n0;
  background-color: $b400;

  &:hover {
    background-color: $b500;
  }
}

.blueDisabled,
.blueLoading {
  background-color: $n400;
  color: $n500;
  cursor: not-allowed;

  &:hover {
    background-color: $n400;
  }
}

.white {
  color: $n700;
  background-color: $n0;
}

.ghost {
  background: $n0;
  border: 1px solid $n800;

  &:hover {
    background-color: $n400;
  }
}

.ghostDisabled,
.ghostLoading {
  background-color: $n400;
  color: $n500;
  border: 1px solid $n500;
  cursor: not-allowed;

  &:hover {
    background-color: $n400;
  }
}

.blank {
  background: none;

  &:hover {
    background-color: $n400;
  }
}

.blankDisabled,
.blankLoading {
  color: $n500;
  cursor: not-allowed;

  &:hover {
    background: none;
  }
}

.link {
  color: $b400;
  background-color: transparent;
  text-decoration: underline;
}

.linkDisabled,
.linkLoading {
  color: $n600;
  cursor: not-allowed;
}
