@import-normalize;

@import './mixins';
@import './base';

@include base();
@include fonts();
@include icons();

// Set root
html,
body,
body > div,
.App {
  @include link-style();

  height: 100%;
}

// Set fonts
$headers: (h1, h2, h3, h4);

@each $h in $headers {
  #{$h} {
    @include text($h);
  }
}

#portal-panel {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $portalPanelZI;
}

#portal-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $portalModalZI;
}
