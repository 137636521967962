@use 'sass:math';

// Layout
$xsWidth: 360px !default;
$smWidth: 600px !default;
$mdWidth: 1024px !default;
$lgWidth: 1440px !default;
$defaultFontSize: 12px !default;
$defaultBorderRadius: 4px !default;
$fontFamily: 'Open Sans', mm-main, arial, sans-serif !default;
$fontWeightBold: 700 !default;
$fontWeightSemiBold: 600 !default;
$fontWeightRegular: 400 !default;
$heightSm: 32px !default;
$heightMd: 40px !default;
$heightLg: 48px !default;
$spacing3Xs: 2px !default;
$spacingXXs: 4px !default;
$spacingXs: 8px !default;
$spacingSm: 12px !default;
$spacingMd: 16px !default;
$spacingLg: 24px !default;
$spacingXl: 32px !default;
$spacingXXL1: 48px !default;
$spacingXXL2: 64px !default;
$spacingXXL3: 96px !default;
$breadcrumbsHeight: 40px;

// Text
$textDisplayFontSize: 48px !default;
$textDisplayLineHeight: 64px !default;
$textH1FontSize: 40px !default;
$textH1LineHeight: 56px !default;
$textH2FontSize: 32px !default;
$textH2LineHeight: 44px !default;
$textH3FontSize: 24px !default;
$textH3LineHeight: 32px !default;
$textH4FontSize: 20px !default;
$textH4LineHeight: 28px !default;
$textBody1FontSize: 16px !default;
$textBody1LineHeight: 24px !default;
$textBody2FontSize: 14px !default;
$textBody2LineHeight: 20px !default;
$textSmallFontSize: 12px !default;
$textSmallLineHeight: 16px !default;
$textXSmallFontSize: 11px !default;
$textXSmallLineHeight: 16px !default;
$textTinyFontSize: 10px !default;
$textTinyLineHeight: 16px !default;
$fontWeightBolder: 800 !default;
$fontWeightBold: 700 !default;
$fontWeightSemiBold: 600 !default;
$fontWeightRegular: 400 !default;

// Z-indexes
$modalZI: 1000;
$portalModalZI: 900;
$panelZI: 850;
$panelBgZI: 800;
$portalPanelZI: 800;
$navbarZI: 800;
$collapseBtnZI: 30;
$panelHeaderZI: 20;
$tooltipZI: 20;
$dropdownZI: 15;
$productSelectorItemZI: 10;
$cgResolvelZI: 2;
$couponZI: 2;
$passwordToastZI: 2;
$toastContentZI: 1;
$tableHeaderZI: 1;
$couponWrapperZI: 1;
$smallViewOrderHeaderZD: 999;

// TooltipModal
$arrowSize: 16px;
$arrowHypot: math.hypot($arrowSize, $arrowSize);
$arrowAngleDiff: math.div($arrowHypot - $arrowSize, 2);
