// neutral Palette Colors (Squik Ink)
$n900: #070f22 !default;
$n800: #0c193a !default;
$n700: #505971 !default;
$n600: #8b91a1 !default;
$n500: #b1b5c0 !default;
$n400: #d8dadf !default;
$n300: #ecedf0 !default;
$n200: #f5f6f7 !default;
$n100: #fafafa !default;
$n0: #fff !default;

// Orange Palette Colors (Sunset Orange)
$o900: #772908 !default;
$o800: #9f3c08 !default;
$o700: #d35c0b !default;
$o600: #f46e00 !default;
$o500: #fc8b1c !default;
$o400: #fba94d !default;
$o300: #fcc078 !default;
$o200: #fdd8a8 !default;
$o100: #fee8cc !default;

// Orange Palette Colors (Va A La Playa)
$v900: #7a3e0f !default;
$v800: #a3670c !default;
$v700: #dd891d !default;
$v600: #f0a22d !default;
$v500: #ffb950 !default;
$v400: #f3c183 !default;
$v300: #ffdf9b !default;
$v200: #ffebb6 !default;
$v100: #fff8e3 !default;

// Balata Green Palette Colors (Balata)
$ba900: #074258 !default;
$ba800: #0d596b !default;
$ba700: #147a85 !default;
$ba600: #1d9e9f !default;
$ba500: #29b9ad !default;
$ba400: #12ccb4 !default;
$ba300: #7ceacd !default;
$ba200: #aaf8dc !default;
$ba100: #d4fbea !default;

// Red Palette Colors (Candy Red)
$r900: #740d24 !default;
$r800: #8c1526 !default;
$r700: #ae222b !default;
$r600: #d03431 !default;
$r500: #f24444 !default;
$r400: #e6685a !default;
$r300: #fbaa8e !default;
$r200: #ffd3bc !default;
$r100: #fcf1e9 !default;

// Green Palette Colors (Polar Green)
$g900: #146351 !default;
$g800: #21785b !default;
$g700: #34956c !default;
$g600: #4cb27c !default;
$g500: #68cf8f !default;
$g400: #8de2a5 !default;
$g300: #a8f0b5 !default;
$g200: #c8facc !default;
$g100: #ecfdec !default;

// Purple Palette Colors (Crocus Purple)
$p900: #271173 !default;
$p800: #391a8c !default;
$p700: #5229ae !default;
$p600: #703cd0 !default;
$p500: #9154f2 !default;
$p400: #b17df7 !default;
$p300: #c698fb !default;
$p200: #dcbbfd !default;
$p100: #efdcfe !default;

// Blue Palette Colors (Berry Blue)
$b900: #050f40 !default;
$b800: #09174e !default;
$b700: #0f2161 !default;
$b600: #152e74 !default;
$b500: #1e3c87 !default;
$b400: #335ab1 !default;
$b300: #91b2ec !default;
$b200: #c4dbff !default;
$b100: #e9efff !default;

// Payment Palette Colors
$pa300: #4f5f81 !default;
$pa200: #8089ac !default;
$pa100: #bac0d0 !default;

// Primaray and secondary colors
$primaryColor: $ba500 !default;
$primaryColorDark: $n800 !default;
$primaryColorText: $n700 !default;
$primaryColorHover: $ba400 !default;
$primaryColorFocus: $ba600 !default;
$primaryColorActive: $ba600 !default;
$primaryColorDisabled: $n400 !default;
$secondaryColor: $n600 !default;
$blackColor: #000 !default;
$darkGrey1Color: #2d2d2d !default;
$darkGrey2Color: #666 !default;
$mediumGrey1Color: #999 !default;
$mediumGrey2Color: #d4d4d4 !default;
$lightGrey1Color: #eee !default;
$lightGrey2Color: #fafafa !default;
$critical: #ff000c;

// Mano mano brand
$proColor: #ffb950 !default;
$proColorDisabled: $n400 !default;
$proColorDark: #e6685a !default;

// Skeleton colors
$skeleton-base-color: #e0e0e0;
$skeleton-highlight-color: #f0f0f0;
