@mixin base() {
  body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', mm-main, arial, sans-serif;
    font-size: 14px;
  }

  *,
  ::after,
  ::before {
    box-sizing: border-box;
  }

  /* stylelint-disable selector-no-qualifying-type */
  button,
  input[type='reset'],
  input[type='button'],
  input[type='submit'],
  input[type='file'] > input[type='button'] {
    &::-moz-focus-inner {
      border: 1px solid transparent;
    }
  }
}
/* stylelint-disable no-invalid-position-at-import-rule */
@mixin fonts() {
  @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');
}

@mixin icons() {
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
}
/* stylelint-enable no-invalid-position-at-import-rule */
