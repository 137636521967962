@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.spinnerImg {
  animation: spin 0.6s linear infinite;

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.small {
  width: 30px;
  height: 30px;
}

.large {
  width: 40px;
  height: 40px;
}
