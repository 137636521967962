@mixin toast-animation($className) {
  .#{$className} {
    position: fixed;
    top: 0;
    right: 0;
    z-index: $modalZI;

    [class*='baseToast'] {
      width: 350px;
      animation: fade-in 0.3s linear, fade-out 0.6s linear 5s;
      position: absolute;
      right: 6px;
      top: 6px;
    }
  }
}

@keyframes fade-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}
